.container > * {
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: @shadow-1-down; */
}

@media screen and (max-width: 320) {
  .container {
    width: 100% !important;
  }
  .container > * {
    border-radius: 0 !important;
  }
}

.menu {
  & :global(.anticon) {
    margin-right: 8px;
  }
  & :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
  & .action {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    & > span {
      vertical-align: middle;
    }
    &:hover {
      background: var(--header-hover);
    }
    & :global(.opened) {
      background: var(--header-hover);
    }
  }
  & .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  & .account {
    & .avatar {
      margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      vertical-align: top;
    }
  }
}

.dark {
  & .action {
    color: rgba(255, 255, 255, 0.85);
    & > span {
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover,
    &:global(.opened) {
      background: var(--primary-color);
    }
  }
}

:global(.ant-pro-global-header) {
  height: 55px;
  & .dark {
    & .action {
      color: var(--text-color);
      & > span {
        color: var(--text-color);
      }
      &:hover {
        color: rgba(255, 255, 255, 0.85);
        & > span {
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
}

@media only screen and (max-width: 480) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
    & .account {
      & .avatar {
        margin-right: 0;
      }
    }
    & .search {
      display: none;
    }
  }
}
