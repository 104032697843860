@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@media (max-width: 320) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    & .ant-table-thead > tr,
    & .ant-table-tbody > tr {
      & > th,
      & > td {
        white-space: pre;
        & > span {
          display: block;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-image-preview-img {
  display: inline-block;
}

/* Common styles */

/* Custom styles */
