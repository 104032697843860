.login {
  & :global {
    & .ant-tabs .ant-tabs-bar {
      margin-bottom: 24px;
      text-align: center;
      border-bottom: 0;
    }
  }

  & .icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;
  }

  & .other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;
  }

  & .submit {
    width: 100%;
    margin-top: 24px;
  }
}
