.standardTable {
  & :global {
    & .ant-table-pagination {
      margin-top: 24px;
    }
  }
  & .tableAlert {
    margin-bottom: 16px;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.toolbarLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.toolbarRight {
  display: flex;
  justify-content: flex-end;
}
.toolbarTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}

.columnSettingList {
  display: flex;
  flex-direction: column;
  width: 100%;
  & :global(.ant-tree .ant-tree-switcher-noop) {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .standardTable {
    & :global {
      & .ant-table-pagination {
        margin-top: 24px;
        text-align: center;
        width: 100%;
      }
    }
  }
}
